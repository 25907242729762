@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  @apply m-0 h-full bg-neutral-100 p-0 font-sans dark:bg-neutral-900 dark:text-neutral-100;
  scroll-behavior: smooth;
}
#__next {
  @apply min-h-full;
}

@layer utilities {
  .text-secondary {
    @apply text-neutral-500 dark:text-neutral-400;
  }

  .bg-background {
    @apply bg-neutral-100 dark:bg-neutral-900;
  }
}
